@tailwind base;
@tailwind components;
@tailwind utilities;


@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.notification-dot {
  position: absolute;
  top: 20px;
  /* display: inline-block; */
  /* margin-right: 8px; */
  left: 80px;
}

.dot {
  display: inline-block;
  width: 16px; /* Set the width and height of the dot */
  height: 16px;
  background-color: red; /* Set the background color of the dot */
  color: white; /* Set the text color inside the dot */
  border-radius: 50%; /* Make the dot circular */
  text-align: center;
  font-size: 12px; /* Set the font size of the number */
  line-height: 16px; /* Set the line height to vertically center the number */
}

